import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  Typography,
} from '@photosi/web-design-system';
import { Trans } from 'react-i18next';
import criticalErrorImage from '../../../_shared/assets/images/critical-error.svg';
import reservedCouponImage from '../../../_shared/assets/images/reserved-coupon.svg';
import { CouponErrorCode } from '../../../_shared/types';

type Props = {
  couponErrorCode: CouponErrorCode;
  isDialogOpen: boolean;
  couponErrorDescription: string;
  setIsDialogOpen: (isOpen: boolean) => void;
};

type CouponErrorMessage = {
  title: string;
  image: string;
};
const couponErrorMessage: Record<CouponErrorCode, CouponErrorMessage> = {
  code_not_found: {
    title: 'checkout.coupon.refusedCoupon.codeNotFound.title',
    image: criticalErrorImage,
  },
  consumer_reserved: {
    title: 'checkout.coupon.refusedCoupon.consumerReserved.title',
    image: reservedCouponImage,
  },
  invalid_cart_products: {
    title: 'checkout.coupon.refusedCoupon.invalidCartProducts.title',
    image: criticalErrorImage,
  },
  min_order_not_reached: {
    title: 'checkout.coupon.refusedCoupon.minOrderNotReached.title',
    image: criticalErrorImage,
  },
  max_usage_reached: {
    title: 'checkout.coupon.refusedCoupon.maxUsageReached.title',
    image: criticalErrorImage,
  },
  code_expired: {
    title: 'checkout.coupon.refusedCoupon.codeExpired.title',
    image: criticalErrorImage,
  },
  invalid_discount: {
    title: 'checkout.coupon.refusedCoupon.invalidDiscount.title',
    image: criticalErrorImage,
  },
  software_not_enabled: {
    title: 'checkout.coupon.refusedCoupon.softwareNotEnabled.title',
    image: criticalErrorImage,
  },
  user_max_usage_reached: {
    title: 'checkout.coupon.refusedCoupon.userMaxUsageReached.title',
    image: criticalErrorImage,
  },
  cumulative_error: {
    title: 'checkout.coupon.refusedCoupon.cumulativeError.title',
    image: criticalErrorImage,
  },
  retailer_reserved: {
    title: 'checkout.coupon.refusedCoupon.retailerReserved.title',
    image: criticalErrorImage,
  },
};

function getCouponErrorMessage(code: CouponErrorCode): CouponErrorMessage {
  return couponErrorMessage[code] || couponErrorMessage.code_not_found;
}

export const RefusedCouponModal: React.FC<Props> = ({
  couponErrorCode,
  isDialogOpen,
  couponErrorDescription,
  setIsDialogOpen,
}) => {
  const { title, image } = getCouponErrorMessage(couponErrorCode);

  return (
    <Dialog onOpenChange={setIsDialogOpen} open={isDialogOpen}>
      <DialogContent className="pds-flex pds-flex-col pds-justify-center pds-items-center">
        <div className="pds-size-28">
          <img src={image} className="pds-bg-cover pds-w-full pds-h-full" />
        </div>
        <DialogHeader className="pds-mt-4">
          <DialogTitle data-testid="modal-title">
            <Typography variant="headingXL">
              <Trans i18nKey={title} />
            </Typography>
          </DialogTitle>
        </DialogHeader>
        <DialogDescription className="pds-text-center pds-mt-1">
          <Typography data-testid="modal-description">{couponErrorDescription}</Typography>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
};
